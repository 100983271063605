import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title"

function OrderPage() {
  const [isInputFocused, setIsInputFocused] = useState(false);

  return (
    <Layout isInputFocused={isInputFocused}>
      <SEO
        pageKeywords={[`miss dulces`, `pasteleria`, `cafeteria`, `pedir`]}
        title="Pedir"
        lang="es"
      />
      <section className="order mt-6 mb-12 md:container md:mx-auto p-5">
        <form id="regForm" action="">
          <Title titleName="Pedir tus pasteles" twSize="text-4xl" />
          <div className="form-stepper md:mx-4 px-4 mb-16 md:mb-24">
            <div className="flex items-center">
              <div className="flex items-center text-black relative">
                <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-myPinkMedium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mail "
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                </div>
                <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-black">
                  Pedir
                </div>
              </div>
              <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-myPinkMedium"></div>
              <div className="flex items-center text-white relative">
                <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 bg-myPinkMedium border-myPinkMedium">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user-plus "
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <line x1="20" y1="8" x2="20" y2="14"></line>
                    <line x1="23" y1="11" x2="17" y2="11"></line>
                  </svg>
                </div>
                <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-black">
                  Información personal
                </div>
              </div>
              <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300"></div>
              <div className="flex items-center text-gray-500 relative">
                <div className="rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 border-gray-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-database "
                  >
                    <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                    <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                    <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                  </svg>
                </div>
                <div className="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase text-gray-500">
                  Revisar
                </div>
              </div>
            </div>
          </div>
          <div className="input-section">
            <div className="flex flex-col md:flex-row">
              <div className="w-full flex-1 md:pr-2">
                <label
                  htmlFor="nombre"
                  className="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mt-3"
                >
                  Nombre
                </label>
                <div className="bg-white my-2 p-1 flex shadow-md rounded">
                  <input
                    name="nombre"
                    id="nombre"
                    placeholder="Mario"
                    className="p-1 px-2 w-full text-gray-800"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                  />
                </div>
              </div>
              <div className="w-full flex-1 md:pl-2">
                <label
                  htmlFor="apellidos"
                  className="font-bold text-gray-600 text-xs leading-8 uppercase h-6 mt-3"
                >
                  Apellidos
                </label>
                <div className="bg-white my-2 p-1 flex shadow-md rounded">
                  <input
                    name="apellidos"
                    id="apellidos"
                    placeholder="Lopéz"
                    className="p-1 px-2 w-full text-gray-800"
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex-1">
            <label
              htmlFor="email"
              className="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
            >
              {" "}
              Correo electrónico
            </label>
            <div className="bg-white my-2 p-1 flex shadow-md rounded">
              <input
                name="email"
                id="email"
                placeholder="mariolopez@ejemplo.com"
                className="p-1 px-2 w-full text-gray-800"
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
              />
            </div>
          </div>
          <div className="w-1/3 mt-4 flex">
            <button className="bg-transparent hover:bg-myViolet text-myViolet font-semibold hover:text-white py-2 px-4 mr-6 border border-myViolet hover:border-transparent rounded">
              Anterior
            </button>
            <button className="bg-myPinkMedium hover:bg-myViolet text-white font-semibold py-2 px-4 rounded">
              Siguiente
            </button>
          </div>
        </form>
      </section>
    </Layout>
  );
}

export default OrderPage;
